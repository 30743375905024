import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/new-age.scss';

class Layout extends Component {
  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'YuriKanagawa HomePage' },
                { name: 'keywords', content: 'site, web' },
                { property:'og:url', content:'https://yurikanagawa.com'},
                { property:'og:image' , content:'https://yurikanagawa.com/ogp_image.png'},
                { name:'og:description' , content:'YuriKanagawa HomePage'},
                { name:'twitter:card',content:'Summary Card'},
                { name:'twitter:title',content:'YuriKanagawa HomePage'},
                { name:'twitter:description',content:'YuriKanagawa HomePage'},
                { name:'twitter:image' , content: 'https://yurikanagawa.com/ogp_image.png'},
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div className={'page-top'}>{children}</div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  noSiteHeader: PropTypes.bool,
  activeLink: PropTypes.string,
};

export default Layout;
